<template>
    <div class="resultPage">
        <img src="../../assets/signFailed.png" />
        <span class="title">会员缴费失败</span>

        <span class="desc">请重新提交缴费</span>
        <Button class="mar-t-40" color="#2b72ff" block @click="submitAgain">重试</Button>
        <Button color="#2b72ff" plain block @click="goMyPage" class="mar-t-15">返回我的</Button>
  </div>
</template>

<script>
import { Button } from "vant";
    export default {
        name:'signFailed',
        components:{Button},
        methods:{
            submitAgain(){
                this.$router.go(-1);
            },
            goMyPage(){
                this.$router.push('/my')
            },
        }
    }
</script>

<style lang="less" scoped>
@import "./common.less";
</style>